const getChartData = (surveyReportData) => {
  const chartData = [];
  const historyData = surveyReportData?.history?.length?surveyReportData.history:[];
  historyData.map(historyDatum => {
    const { asOfDate, twiScore } = historyDatum;
    const date = new Date(asOfDate);
    const newDate = new Date(date).toDateString();
    const splittedDate = newDate.split(' ');
    const refinedDate = splittedDate[1].concat(" ").concat(splittedDate[2]).concat(" ").concat(splittedDate[3]);

    chartData.push(
      {
        dimension: refinedDate,
        twi: twiScore || 0,
        benchmark: surveyReportData.twiScore || 0
      }
    );
    return historyDatum;
  });
  return chartData;
};

const formatSurveyDataForCharts = (surveyReportData) => {

  if (!surveyReportData?.questions?.length) return null;

  return [...getChartData(surveyReportData)];

};

export default formatSurveyDataForCharts;