/**
 * @param {Array} data
 * Transforms plan data into formatted options for a select dropdown
 */
const transformPlanDataToOptions = data => {
  return data.map(plan => {
    return {
      id: `plan-${plan.planCode || plan.id}`,
      value: plan.planName,
      content: (plan.planText && plan.planText.content) || (plan.description && plan.description.content),
      label: plan.planName,
      price: plan.price,
      maxParticipants: plan.maxParticipants,
      onlyTwiCompletion: plan.onlyTwiCompletion,
      showFullDashboard: plan.showFullDashboard,
      planCode: plan.planCode || plan.id,
      selectLink: plan.selectLink,
    };
  });
};

export default transformPlanDataToOptions;
