// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import PlanCard from '../PlanCard';
import PlanSelector from '../PlanSelector';

import transformPlanDataToOptions from '../../utils/transformPlanDataToOptions';

// Import Styled Components
import Section from '../../styles/objects/Section';
import Container from '../../styles/objects/Container';

class Plans extends PureComponent {
  state = {
    selectedPlan: '', // default plan
  };

  // Update preselection from props
  componentWillMount() {
    const { selectedPlan } = this.props;

    this.setState({
      selectedPlan,
    });
  }

  handleChange = e => {
    const { onSelectPlan } = this.props;
    const elem = e.target;

    // we use buttons on desktop, a select on mobile
    // check if its from a select or button
    const selectedPlan = elem.options ? elem.options[elem.selectedIndex].getAttribute('id') : elem.getAttribute('id');

    this.setState({
      selectedPlan,
    });

    if (onSelectPlan instanceof Function) {
      onSelectPlan(e);
    }
  };

  render() {
    const { selectedPlan } = this.state;
    const { planData, selectedPlan: currentPlan, ...props } = this.props;

    if (!planData) return false;
    const planOptions = transformPlanDataToOptions(planData);
    const selectedPlanId = selectedPlan || planOptions[0]?.id;

    const filteredPlan = planOptions.find(plan => plan.id === selectedPlanId);

    return (
      <Section modifiers={{ dots: true, dotsBottom: true }}>
        <Container>
          <PlanSelector handleChange={this.handleChange} selectedPlan={selectedPlanId} options={planOptions} />
          <PlanCard {...props} {...filteredPlan} selectedPlan={currentPlan} />
        </Container>
      </Section>
    );
  }
}

Plans.propTypes = {
  selectedPlan: PropTypes.string,
  planData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectPlan: PropTypes.func,
};

Plans.defaultProps = {
  selectedPlan: null,
  onSelectPlan: null,
};

export default Plans;
