// Import Dependencies
import styled from 'styled-components';

// Import vars
// import * as vars from '../../global/vars';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const Action = styled.div`
  display: inline-block;
  margin: 0 15px 15px;

  ${props =>
    getModifier(props, 'actionDisplayNone') &&
    `
    display: none;
  `};

`;

export default Action;
