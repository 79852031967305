// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils';

// Import Globals
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';
import list from '../../global/list';
import link from '../../global/link';
import typography from '../../global/typography';

const Wysiwyg = styled.div`
  ${typography.body};
  word-break: break-word;

  ${props =>
    getModifier(props, 'whiteBg') &&
    `
        padding: ${vars.spacing.base} 5px;
        background-color: ${vars.colors.white};

      `};

  p {
    margin-bottom: ${vars.spacing.midLarge};
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${vars.spacing.base};
    margin-top: ${vars.spacing.large};

    ${vars.breakpoint.medium`
      margin-bottom: ${vars.spacing.midLarge};
    `};

    ${props =>
    getModifier(props, 'compact') &&
    vars.breakpoint.medium`
        margin-bottom: ${vars.spacing.base};
      `};

    ${props =>
    getModifier(props, 'dark') &&
    `
        color: ${vars.colors.white};
      `};

    &:first-child {
      margin-top: 0;
    }

    @media print {
      display: block;
      text-align: left;
      width: 100%;
    }
  }

  h1 {
    ${typography.heading1};
  }

  h2 {
    ${typography.heading2};
  }

  h3 {
    ${typography.heading3};
  }

  h4 {
    ${typography.heading4};
  }

  h5 {
    ${typography.heading5};
  }

  a {
    ${link};
  }

  small {
    font-size: ${vars.fontSize.small};
    line-height: 1.45;
  }

  ul {
    ${list}

    li {
      padding-left: ${vars.spacing.medium};

      &:before {
        background-color: ${vars.colors.blue500};
        content: '';
        height: ${toRems('8px')};
        top: ${toRems('8px')};
        width: ${toRems('8px')};
      }

      strong {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  ol {
    ${list}
    counter-reset: section;

    li {
      &:before {
        content: counter(section, decimal) '.';
        counter-increment: section;
        padding-right: ${vars.spacing.small};
      }
      strong {
        display: block;
        margin-bottom: 8px;
      }
    }

    ol {
      li {
        &:before {
          content: counter(section);
        }
      }
    }
  }

  dl {
    display: flex;
    background-color: ${vars.colors.white};
    flex-wrap: wrap;
    font-size: ${vars.fontSize.small};
    margin: 0;
    padding: 0;

    @media print {
      font-size: ${vars.fontSize.xSmall};
    }

    dd,
    dt {
      flex: 1 1 50%;
      padding: 0;
      width: 50%;
    }

    dt {
      font-weight: ${vars.fontWeight.bold};
      margin: 0 auto ${vars.spacing.small} 0;

      @media print {
        margin: 0;
        font-weight: normal !important;
      }
    }

    dd {
      margin: 0 0 ${vars.spacing.small} auto;

      @media print {
        color: ${vars.colors.grey350} !important;
        margin: 0;
      }
    }
  }

  blockquote {
    font-size: ${vars.fontSize.base};
    font-weight: ${vars.fontWeight.semibold};
    line-height: 1.4;
    margin: 0 0 ${vars.spacing.base};

    ${vars.breakpoint.medium`
      font-size: ${vars.fontSize.large};
    `}
  }

  /* Modifier: greyLink */
  ${props =>
    getModifier(props, 'greyLink') &&
    `
      a {
        &,
        &:link,
        &:visited {
          box-shadow: 0 -1px 0 0 transparent inset, 0 -3px 0 0 transparent inset;
          color: ${vars.colors.white};
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: 0 -1px 0 0 ${vars.colors.white} inset, 0 -1px 0 0 transparent inset;
        }
      }
  `};

  /* Modifier: smallBottomMargin */
  ${props =>
    getModifier(props, 'smallBottomMargin') &&
    `
      p {
        margin-bottom: ${vars.spacing.xSmall};
      }
  `};

  /* Modifier: alignTextRightOnMedium */
  ${props =>
    getModifier(props, 'alignTextRightOnMedium') &&
    vars.breakpoint.medium`
      text-align: right;
  `};

  /* Modifier: app */
  ${props =>
    getModifier(props, 'app') &&
    `
    font-size: ${vars.fontSize.small};

    @media print {
      font-size: ${vars.fontSize.xSmall};
    }

    p {
      font-size: ${vars.fontSize.small};

      @media print {
        font-size: ${vars.fontSize.xSmall};
      }
    }
  `};

  /* Modifier: marginBottom */
  ${props =>
    getModifier(props, 'marginBottom') &&
    `
      margin-bottom: ${vars.spacing.medium};
  `};

  /* Modifier: maxWidth */
  ${props =>
    getModifier(props, 'maxWidth') &&
    `
      max-width: 340px;
  `};

  /* Overlay */
  ${props =>
    getModifier(props, 'overlay') &&
    `
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;
    -webkit-filter: blur(5px);
    filter: blur(5px);
  `};

  ${props =>
    getModifier(props, 'centerContent') &&
    `
      top: 25%;
      left: 31%;
      position: absolute !important;
      text-align: center;
      max-width: 385px;
  `};
`;

export default Wysiwyg;
