// Import Dependencies
import React from 'react';

// Import Styled Components
import StyledHeader from '../../styles/components/HeaderMarketing';

const HeaderLogo = ({ ...props }) => {
  return (
    <StyledHeader.Logo {...props}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451 119" {...props}>
        <g
          transform="matrix(0.99456749,0,0,0.99456749,-204.98036,-215.79131)"
          >
          <path
            fill="#00B1FF"
            d="m 258.15,315.24 v 20.04 h -7.5 c -10.27,0 -18.14,-2.48 -23.6,-7.45 -5.47,-4.97 -8.2,-13.25 -8.2,-24.85 V 258.95 H 206.1 v -19.71 h 12.75 v -21.95 h 23.35 v 21.95 h 15.7 l -0.1,19.71 h -15.6 v 44.36 c 0,4.42 0.88,7.51 2.65,9.28 1.77,1.77 4.75,2.65 8.94,2.65 z"
            />
          <path
            fill="#00B1FF"
            d="m 411.89,249.71 v 85.57 h -23.35 v -85.57 z"
            />
        </g><path
          fill="#00B1FF"
          d="m 182.67221,3.6997914 c 2.63561,-2.35712 6.0967,-3.54066002 10.38329,-3.54066002 4.28658,0 7.74768,1.18354002 10.38328,3.54066002 2.63561,2.36707 3.95838,5.41045 3.95838,9.1400806 0,3.62022 -1.32277,6.61387 -3.95838,8.98094 -2.6356,2.36707 -6.0967,3.54066 -10.38328,3.54066 -4.28659,0 -7.73774,-1.18353 -10.38329,-3.54066 -2.6356,-2.35712 -3.94843,-5.35077 -3.94843,-8.98094 0,-3.7296306 1.31283,-6.7829506 3.94843,-9.1400806 z"
          /><g
          transform="matrix(0.99456749,0,0,0.99456749,-204.98036,-215.79131)"
          fill="#FFFFFF"
            >
          <path
            d="m 441.52,246.58 v 5.72 h -3.5 c -2.93,0 -5.18,-0.71 -6.74,-2.13 -1.56,-1.42 -2.34,-3.78 -2.34,-7.09 v -11.35 h -3.64 v -5.63 h 3.64 v -7.76 h 6.67 v 7.76 h 5.86 v 5.63 h -5.86 v 11.45 c 0,1.26 0.25,2.14 0.76,2.65 0.5,0.5 1.36,0.76 2.55,0.76 h 2.6 z"
            />
          <path
            d="m 469.14,228.7 c 1.8,1.99 2.7,4.78 2.7,8.37 v 15.23 h -6.62 v -14.47 c 0,-2.08 -0.54,-3.7 -1.61,-4.85 -1.07,-1.15 -2.54,-1.73 -4.4,-1.73 -1.89,0 -3.4,0.61 -4.54,1.84 -1.14,1.23 -1.7,3 -1.7,5.3 v 13.91 h -6.67 v -33.96 h 6.67 v 13.01 c 0.69,-1.77 1.84,-3.15 3.43,-4.14 1.59,-0.99 3.43,-1.49 5.51,-1.49 3.02,0 5.43,0.99 7.23,2.98 z"
            />
          <path
            d="m 502.11,240.48 h -19.2 c 0.09,2.33 0.73,4.05 1.89,5.16 1.17,1.1 2.62,1.66 4.35,1.66 1.54,0 2.83,-0.38 3.85,-1.14 1.02,-0.76 1.68,-1.78 1.96,-3.07 h 7.05 c -0.35,1.83 -1.09,3.47 -2.22,4.92 -1.14,1.45 -2.59,2.59 -4.38,3.41 -1.78,0.82 -3.76,1.23 -5.94,1.23 -2.55,0 -4.83,-0.54 -6.81,-1.63 -1.99,-1.09 -3.53,-2.64 -4.64,-4.66 -1.1,-2.02 -1.66,-4.4 -1.66,-7.14 0,-2.74 0.55,-5.13 1.66,-7.17 1.1,-2.03 2.65,-3.59 4.64,-4.66 1.99,-1.07 4.26,-1.61 6.81,-1.61 2.58,0 4.85,0.54 6.79,1.61 1.94,1.07 3.44,2.55 4.49,4.42 1.06,1.88 1.58,4.01 1.58,6.41 0.01,0.65 -0.07,1.4 -0.22,2.26 z m -8.16,-7.81 c -1.21,-1.1 -2.7,-1.66 -4.47,-1.66 -1.8,0 -3.31,0.55 -4.54,1.66 -1.23,1.1 -1.91,2.73 -2.03,4.87 h 12.72 c 0.09,-2.14 -0.47,-3.76 -1.68,-4.87 z"
            />
          <path
            d="m 427.89,300.39 c 0.76,-0.68 1.75,-1.02 2.98,-1.02 1.23,0 2.22,0.34 2.98,1.02 0.76,0.68 1.13,1.55 1.13,2.63 0,1.04 -0.38,1.9 -1.13,2.58 -0.76,0.68 -1.75,1.02 -2.98,1.02 -1.23,0 -2.22,-0.34 -2.98,-1.02 -0.76,-0.68 -1.14,-1.54 -1.14,-2.58 0.01,-1.08 0.39,-1.95 1.14,-2.63 z m 6.29,8.68 v 26.2 h -6.67 v -26.2 z"
            />
          <path
            d="m 463.09,311.68 c 1.8,1.99 2.7,4.78 2.7,8.37 v 15.23 h -6.62 v -14.47 c 0,-2.08 -0.54,-3.7 -1.61,-4.85 -1.07,-1.15 -2.54,-1.73 -4.4,-1.73 -1.89,0 -3.4,0.61 -4.54,1.84 -1.14,1.23 -1.7,3 -1.7,5.3 v 13.91 h -6.67 v -26.2 h 6.67 v 5.25 c 0.69,-1.77 1.84,-3.15 3.43,-4.14 1.59,-0.99 3.43,-1.49 5.51,-1.49 3.02,0 5.43,0.99 7.23,2.98 z"
            />
          <path
            d="m 487.56,310.3 c 1.56,1.04 2.69,2.44 3.38,4.21 v -13.67 h 6.62 v 34.44 h -6.62 v -5.49 c -0.69,1.77 -1.82,3.18 -3.38,4.23 -1.56,1.06 -3.45,1.58 -5.65,1.58 -2.21,0 -4.19,-0.54 -5.96,-1.61 -1.77,-1.07 -3.15,-2.63 -4.14,-4.66 -0.99,-2.03 -1.49,-4.42 -1.49,-7.17 0,-2.74 0.5,-5.13 1.49,-7.17 0.99,-2.03 2.37,-3.59 4.14,-4.66 1.77,-1.07 3.75,-1.61 5.96,-1.61 2.21,0.02 4.09,0.54 5.65,1.58 z m -8.58,6.3 c -1.26,1.36 -1.89,3.22 -1.89,5.58 0,2.36 0.63,4.22 1.89,5.56 1.26,1.34 2.93,2.01 5.01,2.01 2.02,0 3.68,-0.69 4.99,-2.06 1.31,-1.37 1.96,-3.21 1.96,-5.51 0,-2.33 -0.65,-4.19 -1.96,-5.56 -1.31,-1.37 -2.97,-2.06 -4.99,-2.06 -2.08,0 -3.75,0.68 -5.01,2.04 z"
            />
          <path
            d="m 528.03,323.45 h -19.2 c 0.09,2.33 0.73,4.05 1.89,5.16 1.17,1.1 2.62,1.66 4.35,1.66 1.54,0 2.83,-0.38 3.85,-1.14 1.02,-0.76 1.68,-1.78 1.96,-3.07 h 7.05 c -0.35,1.83 -1.09,3.47 -2.22,4.92 -1.14,1.45 -2.59,2.59 -4.38,3.41 -1.78,0.82 -3.76,1.23 -5.94,1.23 -2.55,0 -4.83,-0.54 -6.81,-1.63 -1.98,-1.09 -3.53,-2.64 -4.64,-4.66 -1.1,-2.02 -1.66,-4.4 -1.66,-7.14 0,-2.74 0.55,-5.13 1.66,-7.17 1.1,-2.03 2.65,-3.59 4.64,-4.66 1.99,-1.07 4.26,-1.61 6.81,-1.61 2.58,0 4.85,0.54 6.79,1.61 1.94,1.07 3.44,2.55 4.49,4.42 1.06,1.88 1.58,4.01 1.58,6.41 0.01,0.66 -0.07,1.41 -0.22,2.26 z m -8.16,-7.8 c -1.21,-1.1 -2.7,-1.66 -4.47,-1.66 -1.8,0 -3.31,0.55 -4.54,1.66 -1.23,1.1 -1.91,2.73 -2.03,4.87 h 12.72 c 0.09,-2.14 -0.47,-3.77 -1.68,-4.87 z"
            />
          <path
            d="m 547.52,335.28 -5.96,-8.42 -5.01,8.42 h -7.33 l 8.66,-13.67 -8.85,-12.53 h 7.47 l 5.82,8.28 5.01,-8.28 h 7.33 l -8.8,13.29 9.13,12.91 z"
            />
        </g><g
          fill="#ffffff"
          transform="matrix(0.99456749,0,0,0.99456749,-204.98036,-215.79131)"
          >
          <path
            d="m 452.2,263.7 c 2.74,2.14 4.55,5.06 5.44,8.75 h -7 c -0.73,-1.8 -1.85,-3.22 -3.38,-4.26 -1.53,-1.04 -3.37,-1.56 -5.51,-1.56 -1.77,0 -3.36,0.44 -4.78,1.32 -1.42,0.88 -2.52,2.14 -3.31,3.78 -0.79,1.64 -1.18,3.56 -1.18,5.77 0,2.17 0.39,4.08 1.18,5.72 0.79,1.64 1.89,2.9 3.31,3.78 1.42,0.88 3.01,1.32 4.78,1.32 2.14,0 3.98,-0.52 5.51,-1.56 1.53,-1.04 2.66,-2.46 3.38,-4.26 h 7 c -0.88,3.69 -2.7,6.6 -5.44,8.75 -2.74,2.14 -6.19,3.22 -10.36,3.22 -3.18,0 -6,-0.72 -8.44,-2.15 -2.44,-1.43 -4.33,-3.44 -5.67,-6.03 -1.34,-2.58 -2.01,-5.52 -2.01,-8.79 0,-3.31 0.67,-6.26 2.01,-8.84 1.34,-2.58 3.22,-4.59 5.65,-6.03 2.43,-1.43 5.25,-2.15 8.46,-2.15 4.17,0.01 7.62,1.08 10.36,3.22 z"
            />
          <path
            d="m 487.33,268 v 26.2 h -6.67 v -5.3 c -0.69,1.77 -1.84,3.14 -3.43,4.14 -1.59,0.99 -3.43,1.49 -5.51,1.49 -3.03,0 -5.44,-0.99 -7.23,-2.98 -1.79,-1.99 -2.7,-4.76 -2.7,-8.32 V 268 h 6.62 v 14.47 c 0,2.08 0.54,3.7 1.61,4.85 1.07,1.15 2.52,1.73 4.35,1.73 1.92,0 3.45,-0.61 4.59,-1.84 1.14,-1.23 1.7,-2.99 1.7,-5.3 V 268 Z"
            />
          <path
            d="m 509.3,270.08 c 1.92,1.61 3.12,3.75 3.59,6.43 h -6.24 c -0.25,-1.26 -0.82,-2.27 -1.7,-3.03 -0.88,-0.76 -2.03,-1.13 -3.45,-1.13 -1.14,0 -2.02,0.27 -2.65,0.8 -0.63,0.54 -0.95,1.26 -0.95,2.18 0,0.73 0.25,1.31 0.76,1.75 0.5,0.44 1.13,0.79 1.89,1.04 0.76,0.25 1.84,0.55 3.26,0.9 1.99,0.44 3.6,0.91 4.85,1.4 1.25,0.49 2.32,1.26 3.22,2.32 0.9,1.06 1.35,2.48 1.35,4.28 0,2.24 -0.88,4.05 -2.62,5.44 -1.75,1.39 -4.11,2.08 -7.07,2.08 -3.41,0 -6.13,-0.76 -8.18,-2.29 -2.05,-1.53 -3.29,-3.71 -3.74,-6.55 H 498 c 0.16,1.29 0.73,2.31 1.7,3.05 0.98,0.74 2.25,1.11 3.83,1.11 1.13,0 2,-0.28 2.6,-0.83 0.6,-0.55 0.9,-1.27 0.9,-2.15 0,-0.76 -0.26,-1.37 -0.78,-1.84 -0.52,-0.47 -1.17,-0.83 -1.94,-1.09 -0.77,-0.25 -1.87,-0.55 -3.29,-0.9 -1.95,-0.44 -3.54,-0.89 -4.75,-1.35 -1.21,-0.46 -2.25,-1.19 -3.12,-2.2 -0.87,-1.01 -1.3,-2.4 -1.3,-4.16 0,-2.27 0.87,-4.11 2.6,-5.53 1.73,-1.42 4.13,-2.13 7.19,-2.13 3.18,-0.01 5.74,0.8 7.66,2.4 z"
            />
          <path
            d="m 531.81,288.48 v 5.72 h -3.5 c -2.93,0 -5.18,-0.71 -6.74,-2.13 -1.56,-1.42 -2.34,-3.78 -2.34,-7.09 v -11.35 h -3.64 V 268 h 3.64 v -6.48 h 6.67 V 268 h 5.86 v 5.63 h -5.86 v 11.44 c 0,1.26 0.25,2.14 0.76,2.65 0.5,0.5 1.36,0.76 2.55,0.76 z"
            />
          <path
            d="m 554.2,269.28 c 2.03,1.07 3.63,2.62 4.8,4.66 1.17,2.03 1.75,4.42 1.75,7.16 0,2.74 -0.58,5.12 -1.75,7.14 -1.17,2.02 -2.77,3.57 -4.8,4.66 -2.03,1.09 -4.33,1.63 -6.88,1.63 -2.55,0 -4.85,-0.54 -6.88,-1.63 -2.03,-1.09 -3.63,-2.64 -4.8,-4.66 -1.17,-2.02 -1.75,-4.4 -1.75,-7.14 0,-2.74 0.58,-5.13 1.75,-7.16 1.17,-2.03 2.77,-3.59 4.8,-4.66 2.03,-1.07 4.33,-1.61 6.88,-1.61 2.55,0 4.84,0.54 6.88,1.61 z m -11.61,6.12 c -1.29,1.31 -1.94,3.21 -1.94,5.7 0,2.49 0.65,4.39 1.94,5.7 1.29,1.31 2.87,1.96 4.73,1.96 1.86,0 3.44,-0.65 4.73,-1.96 1.29,-1.31 1.94,-3.21 1.94,-5.7 0,-2.49 -0.65,-4.39 -1.94,-5.7 -1.29,-1.31 -2.87,-1.96 -4.73,-1.96 -1.86,0 -3.44,0.66 -4.73,1.96 z"
            />
          <path
            d="m 605.97,270.58 c 1.84,1.97 2.77,4.77 2.77,8.39 v 15.23 h -6.62 v -14.47 c 0,-2.02 -0.51,-3.57 -1.54,-4.66 -1.02,-1.09 -2.44,-1.63 -4.23,-1.63 -1.86,0 -3.34,0.58 -4.44,1.75 -1.1,1.17 -1.66,2.87 -1.66,5.11 v 13.9 h -6.62 v -14.47 c 0,-2.02 -0.51,-3.57 -1.54,-4.66 -1.03,-1.09 -2.44,-1.63 -4.23,-1.63 -1.86,0 -3.34,0.58 -4.44,1.75 -1.1,1.17 -1.66,2.87 -1.66,5.11 v 13.9 h -6.67 V 268 h 6.67 v 4.96 c 0.69,-1.7 1.79,-3.02 3.29,-3.95 1.5,-0.93 3.24,-1.4 5.23,-1.4 2.11,0 3.95,0.5 5.51,1.51 1.56,1.01 2.73,2.46 3.52,4.35 0.82,-1.8 2.04,-3.22 3.66,-4.28 1.62,-1.06 3.44,-1.58 5.46,-1.58 3.19,0.02 5.7,1 7.54,2.97 z"
            />
          <path
            d="m 638.62,282.38 h -19.2 c 0.09,2.33 0.72,4.05 1.89,5.15 1.17,1.1 2.62,1.65 4.35,1.65 1.54,0 2.83,-0.38 3.85,-1.13 1.02,-0.76 1.68,-1.78 1.96,-3.07 h 7.05 c -0.35,1.83 -1.09,3.47 -2.22,4.92 -1.13,1.45 -2.59,2.58 -4.37,3.4 -1.78,0.82 -3.76,1.23 -5.93,1.23 -2.55,0 -4.82,-0.54 -6.81,-1.63 -1.99,-1.09 -3.53,-2.64 -4.63,-4.66 -1.1,-2.02 -1.66,-4.4 -1.66,-7.14 0,-2.74 0.55,-5.13 1.66,-7.16 1.1,-2.03 2.65,-3.59 4.63,-4.66 1.99,-1.07 4.26,-1.61 6.81,-1.61 2.58,0 4.85,0.54 6.79,1.61 1.94,1.07 3.44,2.55 4.49,4.42 1.06,1.88 1.58,4.01 1.58,6.41 0,0.66 -0.08,1.42 -0.24,2.27 z m -8.15,-7.8 c -1.21,-1.1 -2.7,-1.66 -4.47,-1.66 -1.8,0 -3.31,0.55 -4.54,1.66 -1.23,1.11 -1.91,2.73 -2.03,4.87 h 12.72 c 0.09,-2.15 -0.47,-3.77 -1.68,-4.87 z"
            />
          <path
            d="m 653.75,269.07 c 1.61,-0.96 3.4,-1.44 5.39,-1.44 v 7.14 h -1.94 c -4.89,0 -7.33,2.27 -7.33,6.81 V 294.2 H 643.2 V 268 h 6.67 v 5.01 c 0.98,-1.66 2.28,-2.98 3.88,-3.94 z"
            />
        </g><g
          transform="matrix(0.99456749,0,0,0.99456749,-204.98036,-215.79131)"
          >
          <path
            fill="#00B1FF"
            d="m 357.42,228.28 c -9.65,-7.54 -21.8,-11.31 -36.44,-11.31 -11.32,0 -21.24,2.52 -29.79,7.57 -8.54,5.05 -15.17,12.12 -19.88,21.22 -4.71,9.1 -7.07,19.47 -7.07,31.12 0,11.54 2.36,21.85 7.07,30.95 4.71,9.1 11.37,16.17 19.97,21.22 8.59,5.05 18.49,7.57 29.7,7.57 14.64,0 26.79,-3.77 36.44,-11.31 9.65,-7.54 16.03,-17.8 19.13,-30.78 h -24.63 c -2.55,6.32 -6.52,11.31 -11.9,14.97 -5.38,3.66 -11.84,5.49 -19.39,5.49 -6.21,0 -11.81,-1.55 -16.81,-4.66 -4.99,-3.1 -8.88,-7.54 -11.65,-13.31 -2.77,-5.77 -4.16,-12.48 -4.16,-20.13 0,-7.76 1.39,-14.53 4.16,-20.3 2.77,-5.77 6.66,-10.2 11.65,-13.31 4.99,-3.1 10.59,-4.66 16.81,-4.66 7.54,0 14,1.83 19.39,5.49 5.29,3.6 9.21,8.5 11.76,14.67 h 24.68 c -3.12,-12.86 -9.47,-23.02 -19.04,-30.5 z"
            />
        </g>
      </svg>
    </StyledHeader.Logo>
  );
};

export default HeaderLogo;
